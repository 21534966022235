*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
html {
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.3;
}
[tabindex="-1"]:focus {
  outline: none !important;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem 0;
}
img {
  vertical-align: middle;
}
[role="button"] {
  cursor: pointer;
}
a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
  background-color: #fff;
}
caption {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #fff;
  text-align: center;
  caption-side: bottom;
}
th {
  text-align: left;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
textarea {
  line-height: inherit;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
input[type="search"] {
  -webkit-appearance: none;
}
output {
  display: inline-block;
}
svg:not(:root) {
  overflow: hidden;
  vertical-align: middle;
}
[hidden] {
  display: none !important;
}
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="email"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: inherit;
}
