@font-face {
  font-family: "Cormorant Garamond";
  src: url("../assets/fonts/CormorantGaramond-Regular.eot");
  src: local("Cormorant Garamond Regular"), local("CormorantGaramond-Regular"),
    url("../assets/fonts/CormorantGaramond-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/CormorantGaramond-Regular.woff2") format("woff2"),
    url("../assets/fonts/CormorantGaramond-Regular.woff") format("woff"),
    url("../assets/fonts/CormorantGaramond-Regular.ttf") format("truetype"),
    url("../assets/fonts/CormorantGaramond-Regular.svg#CormorantGaramond-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url("../assets/fonts/CormorantGaramond-Italic.eot");
  src: local("Cormorant Garamond Italic"), local("CormorantGaramond-Italic"),
    url("../assets/fonts/CormorantGaramond-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/CormorantGaramond-Italic.woff2") format("woff2"),
    url("../assets/fonts/CormorantGaramond-Italic.woff") format("woff"),
    url("../assets/fonts/CormorantGaramond-Italic.ttf") format("truetype"),
    url("../assets/fonts/CormorantGaramond-Italic.svg#CormorantGaramond-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url("../assets/fonts/CormorantGaramond-Bold.eot");
  src: local("Cormorant Garamond Bold"), local("CormorantGaramond-Bold"),
    url("../assets/fonts/CormorantGaramond-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/CormorantGaramond-Bold.woff2") format("woff2"),
    url("../assets/fonts/CormorantGaramond-Bold.woff") format("woff"),
    url("../assets/fonts/CormorantGaramond-Bold.ttf") format("truetype"),
    url("../assets/fonts/CormorantGaramond-Bold.svg#CormorantGaramond-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Deco";
  src: url("../assets/fonts/Haarlem-Deco.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Signmaster";
  src: url("../assets/fonts/Signmaster-Basic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
